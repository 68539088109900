import React from 'react'
import { Parallax } from 'react-scroll-parallax'

export default function ParallaxIcon(props) {
    return (
        <Parallax className="parallax-Icon" styleOuter={{left:`${props.left}%`,top:`${props.top}%`}} y={props.parallax} tagOuter="figure">
            <img src={props.src} alt="" />
        </Parallax>
    )
}
